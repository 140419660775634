import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
export default function ContactForm(props) {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    console.log(form.current);
    emailjs
      .sendForm('service_xjm8p45', 'template_cm8re3g', form.current, {
        publicKey: 'tlNa5sUVjzeF27Ywz',
      })
      .then(
        () => {
          props.setShowContactModal(false);
          console.log('SUCCESS!');
          toast.success('Email sent successfully!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error('Failed to send email. Please try again!', {
            // ... similar toast options
          });
        },
      );
  };
  if (!props.showContactModal) {
    return null;
  }
  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal show={true} onHide={()=>{props.setShowContactModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title className="ms-3 t-cyan">Contact</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row>
              <Col className="text-center">
                <Form ref={form} onSubmit={sendEmail}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control type="text" name="name" placeholder="Name" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="email" name="email" placeholder="Email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicMessage">
                    <Form.Control as="textarea" name="message" rows={8} placeholder="Message" />
                  </Form.Group>

                  <Button className="btn btn-main" type="submit">
                    Send
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>
    </div>
    
  );
};