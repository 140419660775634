import React, { useRef, lazy, Suspense, useState, useEffect } from "react"
import Accordion from "react-bootstrap/Accordion"
import MyAccHeader from "./accordion_header"
import Loader from "../loader/loader"
import ReactGA from "react-ga4"
import { Helmet } from "react-helmet"

const GenericAccordion = ({ sections, getUserID, setLogoSize, setTopBg, setBottomBg }) => {
  const [activeKey, setActiveKey] = useState("");
  const accordionRefs = useRef({});
  const [loadedSections, setLoadedSections] = useState({});

  useEffect(() => {
    if (activeKey && accordionRefs.current[activeKey]) {
      setLoadedSections((prev) => ({
        ...prev,
        [activeKey]: true,
      }));
      setTimeout(() => {
        accordionRefs.current[activeKey].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 500);
      const newUrl = `/${activeKey}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
      setLogoSize('small'); // Set logo size to small when an accordion item is expanded
      const section = sections.find((s) => s.eventKey === activeKey);
      if (section) {
      setTopBg(section.top_bg);
      setBottomBg(section.bot_bg);
      }
    }
    else {
      setLogoSize('large'); // Set logo size to large when all accordion items are collapsed
      setTopBg('');
      setBottomBg('/images/bb_bg_bot.jpg');
    }
  }, [activeKey]);

  useEffect(() => {
    const path = window.location.pathname.split("/");

    // Assuming the path format is /accordionKey
    if (path.length > 1 && path[1]) {
      setActiveKey(path[1]);
    }
  }, []);

  const catchSection = (section_name, section_emoji) => {
    const userID = getUserID(); // Assuming getUserID is passed as a prop
    ReactGA.event({
      category: "section open",
      action: `${section_emoji} ${section_name}`,
      label: userID ? `UserID: ${userID}` : "UserID: Anonymous",
    });
  };

  const changeSection = (newKey, event) => {
    event.stopPropagation();
    setTimeout(() => {
      setActiveKey(newKey === activeKey ? "" : newKey);
      if (loadedSections[newKey] == null) {
        setLoadedSections((prev) => ({
          ...prev,
          [newKey]: true,
        }));
      }
      // Find the section and send Google Analytics event
      const section = sections.find((s) => s.eventKey === newKey);
      if (section) {
        catchSection(section.title, section.iconLetter);
      }
    }, 100);
  };


  return (
    <Accordion activeKey={activeKey} onSelect={(eventKey, e) => changeSection(eventKey, e)}>
      {sections.map((section) => (
        <Accordion.Item
          key={section.eventKey}
          eventKey={section.eventKey}
          className={activeKey === section.eventKey ? "accordion-item-chosen" : ""}
          ref={(el) => (accordionRefs.current[section.eventKey] = el)}
        >
          <Accordion.Header>
            <MyAccHeader
              iconLetter={section.iconLetter}
              image={section.icon}
              title={section.title}
              description={section.description}
              shorty={section.shorty}
              num={section.num}
              isActive={activeKey === section.eventKey}
            />
          </Accordion.Header>
          <Accordion.Body aria-hidden={activeKey !== section.eventKey ? "true" : "false"}>
            {loadedSections[section.eventKey] ? (
              <React.Suspense fallback={<Loader />}>
                <div className="fade-in ms-3 ms-lg-0">
                  <section.Component />
                </div>
              </React.Suspense>
            ) : (
              <div></div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default GenericAccordion;


