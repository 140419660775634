import React from "react"

const Hub = React.lazy(() => import("../components/home/hub"))
const Trailhead = React.lazy(() => import("../components/home/trailhead"))
const Assessment = React.lazy(() => import("../components/home/assessment"))
const Mining = React.lazy(() => import("../components/home/mining"))
const Manufacturing = React.lazy(() => import("../components/home/manufacturing"))
export const works = [
  {
    num: "1",
    eventKey: "hub",
    og_image: '',
    icon: "/images/works/smo/hero.png",
    top_bg: "/images/works/smo/bg_top.png",
    bot_bg: "/images/works/smo/bg_bot.png",
    title: "Marketing Resource Hub",
    description: "A resource hub for Salesforce Marketing Team",
    shorty: "Hub",
    Component: Hub,
    height: 300,
  },
  {
    num: "2",
    eventKey: "trailhead",
    og_image: '',
    icon: "/images/works/trailhead/hero.png",
    top_bg: "/images/works/trailhead/bg_top.png",
    bot_bg: "/images/works/trailhead/bot_bg.jpg",
    title: "Trailhead Challenge",
    shorty: "Web",
    Component: Trailhead,
    description: "A customizable learning platform for Salesforce clients",
    height: 700,
  },
  {
    num: "3",
    eventKey: "assessment",
    og_image: '',
    icon: "/images/works/assess/hero.png",
    top_bg: "/images/works/assess/bg_top.png",
    bot_bg: "/images/works/assess/bg_bot.png",
    title: "AI Competency Assessment",
    shorty: "Assessment",
    Component: Assessment,
    description: "An AI competency assessment tool for Salesforce clients",
    height: 700,
  },
  {
    num: "4",
    eventKey: "mining",
    og_image: '',
    icon: "/images/works/mining/hero.png",
    top_bg: "/images/works/mining/bg_top.png",
    bot_bg: "/images/works/mining/bg_bot.png",
    title: "Mining Animation",
    shorty: "Mining",
    Component: Mining,
    description: "Promotional materials for mining companies",
    height: 700,
  },
  {
    num: "5",
    eventKey: "manufacturing",
    og_image: '',
    icon: "/images/works/industrial/hero.png",
    top_bg: "/images/works/industrial/bg_top.png",
    bot_bg: "/images/works/industrial/bg_bot.png",
    title: "Manufacturing Animation",
    shorty: "Tech Animation",
    Component: Manufacturing,
    description: "Corporate videos for manufacturing companies",
    height: 700,
  },
]
