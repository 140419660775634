import React from 'react';

export default function AccordionHeaderWithImage(props) {
  const defaultBackground = `/images/header_bgs/bg_${props.num}.svg`;
  const hoverBackground = `/images/header_bgs/bg_${props.num}_hover.svg`;
  // const handleMouseLeave = (e) => {
  //   const overlay = e.currentTarget.querySelector('.accordion-button-title-background-overlay');
  //   if (!props.isActive) {
  //     overlay.style.backgroundImage = `url(${defaultBackground})`;
  //   }
  // };

  return (
    <>
      {props.image !== null && props.image !== '' ? <img alt={props.title} src={props.image} className="accordion-button-image"/> : ""}
      <div 
        className={`accordion-button-title ${props.isActive ? 'active' : ''}`}
        // onMouseEnter={(e) => {
        //   const overlay = e.currentTarget.querySelector('.accordion-button-title-background-overlay');
        //   overlay.style.backgroundImage = `url(${hoverBackground})`;
        // }}
        // onMouseLeave={handleMouseLeave}
        // onClick={handleMouseLeave}  // Added onClick event handler
      >
        <div className="accordion-button-title-text">{props.title}</div>
        <div className="accordion-button-title-text-large d-none d-lg-block">{props.title}</div>
        <div className="accordion-button-title-text-large d-block d-lg-none">{props.shorty}</div>
        <div className="accordion-button-title-description">{props.description}</div>
        <div
          className="accordion-button-title-background-overlay"
          style={{ backgroundImage: props.isActive ?  `url(${hoverBackground})` : `url(${defaultBackground})` }}
        />
      </div>
    </>
  );
}