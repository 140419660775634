import React, { Suspense, useState, useEffect } from "react"
// import { Tab, Tabs } from "react-bootstrap"
// import TabItem from "./components/elements/tabs/tab_header_with_image"

// import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom"
import Nav from "./components/elements/nav"
import GenericAccordion from "./components/elements/accordion/generic_accordion"
import { works} from "./config/section_data"
// import Home from "./pages/home_page"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ThemeProvider from "react-bootstrap/ThemeProvider"
// import Loader from "./components/elements/loader/loader"
import ContactModal from "./components/elements/contact_modal"
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent"
import "./App.scss"
import ReactGA from "react-ga4"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App() {
  // const [activeTab, setActiveTab] = useState("part1")
  const [showContactModal, setShowContactModal] = useState(false)
  const [logoSize, setLogoSize] = useState('large')
  const [topBg, setTopBg] = useState('');
  const [bottomBg, setBottomBg] = useState('/images/bb_bg_bot.jpg');
  const generateUserID = () => {
    return "uid_" + Math.random().toString(36).substr(2, 9)
  }

  // Function to get or create a user ID
  const getUserID = () => {
    let userID = localStorage.getItem("userID")
    if (!userID) {
      userID = generateUserID()
      localStorage.setItem("userID", userID)
    }
    return userID
  }
  const openContactModal = (e) => {
    e.preventDefault()
    setShowContactModal(true)
  }

  const handleAcceptCookie = () => {
    ReactGA.initialize("G-LJGXPECZFB", {
      gaOptions: {
        userId: getUserID(),
      },
    })
  }
  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga")
    Cookies.remove("_gat")
    Cookies.remove("_gid")
  }

  useEffect(() => {
    ReactGA.initialize("G-LJGXPECZFB")
    const isConsent = getCookieConsentValue()
    if (isConsent === "true") {
      handleAcceptCookie()
    }
  }, [])

  return (
    <div className='App'>
      <div id="bg" style={{ backgroundImage: `url(${topBg}), url(${bottomBg})` }}/>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint='xxs'
      >
        <ToastContainer />

        <Container className='main-container'>
          <Row>
            <Col className="text-center">
              <img src='/images/logo.svg' alt='logo' className={`logo ${logoSize}`} />
              <h1 className='main-title bold text-center d-none'>
                Boris Babkin
              </h1>
              {/* <p className='main-subtitle text-center'>
                portfolio
              </p> */}
            </Col>
          </Row>
            <GenericAccordion 
            getUserID={getUserID} 
            sections={works} 
            setLogoSize={setLogoSize}
            setTopBg={setTopBg}
            setBottomBg={setBottomBg}
            />

           <Row className='d-inline mt-4 pt-4 pb-4 mb-4'>
            <Col className='text-center'>
              <a href='#' className='contact' onClick={()=>{setShowContactModal(true)}}>
                <img
                  src='/images/contact.svg'
                  alt='contact'
                  className='mailer-link-image contact-image'
                />
              </a>
            </Col>
          </Row>
        </Container>
        {/* <Home reportSectionExpand={reportSectionExpand} getUserID={getUserID}/> */}
        <CookieConsent
          acceptOnScroll={true}
          acceptOnScrollPercentage={5}
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
          buttonText='OK'
          enableDeclineButton
          flipButtons
          style={{
            background: "#EEE",
            color: "#222",
          }}
        >
          <div className='small mb-2'>
            This website uses cookies to enhance the user experience.
          </div>
        </CookieConsent>
        <ContactModal
        showContactModal={showContactModal}
        setShowContactModal={setShowContactModal}
        />
      </ThemeProvider>
    </div>
  )
}

export default App
